import React from 'react';
import {withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from "react-router-dom";
import Button from '@material-ui/core/Button';
import {testata_ordini} from './configurations/testate';

const styles = theme => ({
  table: {
    minWidth: 650,
  },
});


class Testata extends React.Component {
    
    constructor(props){
        super(props);
        
        let newState = {};
        testata_ordini.righe.map(ordine => {
            newState[ordine.id] = '';
        });
        this.state = newState;
    }
    
    resize = () => this.forceUpdate()

    componentDidMount() {
      window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resize)
    }
    
    /*data = [
        {   
            id: 1,
            testata:
            {
                rag:    'A.C.M.E', 
                piva:   '000000', 
                fat:    1000000, 
                dat:    '2020-01-01', 
                tim:    '10:22', 
                cod:    'ART1'
            }, 
            righe:
            [
                {
                    id:     1,
                    cod:    '123', 
                    name:   'Primo articolo', 
                    qty:    5
                },
                {
                    id:     2,
                    cod:    '124', 
                    name:   'Secondo articolo', 
                    qty:    2
                }
            ],
            evasioni:
            [
                [
                    {
                        riga_id:    2,
                        qty:        1
                    }
                ],
                [
                    {
                        riga_id:    1,
                        qty:        3
                    },
                    {
                        riga_id:    2,
                        qty:        1
                    }
                ]
            ]
        }
    ];*/
    
    deleteRow(id){        
        let oldTestata = localStorage.getItem(testata_ordini.id);
        if(oldTestata != null){
            oldTestata = JSON.parse(oldTestata);
            
            let selectedTestata = null;
            oldTestata.map(singleLine => {
                if(singleLine.id == this.props.id){
                    selectedTestata = singleLine;
                }
            });
            
            let newRighe = selectedTestata.righe.filter(riga => {
                if(riga.id === id){
                    return false;
                }else{
                    return true;
                }
            });
            
            selectedTestata.righe = newRighe;
            
            let newTestata =  oldTestata.map(singleLine => {
                if(singleLine.id == this.props.id){
                    return selectedTestata;
                }
                return singleLine;
            });
            localStorage.setItem(testata_ordini.id, JSON.stringify(newTestata));
            this.forceUpdate();
        }
    }
    
    saveRiga = () => {
        let newRiga = {};
        newRiga.values = {};
        testata_ordini.righe.map(ordine => {
            newRiga.values[ordine.id] = this.state[ordine.id];
        });
        
        let oldTestata = localStorage.getItem(testata_ordini.id);
        if(oldTestata != null){
            oldTestata = JSON.parse(oldTestata);
            
            let selectedTestata = null;
            oldTestata.map(singleLine => {
                if(singleLine.id == this.props.id){
                    selectedTestata = singleLine;
                }
            });
            
            let id = 1;
            selectedTestata.righe.forEach(riga => {
                if(riga.id >= id){
                    id = riga.id+1;
                }
            });
            newRiga.id = id;
            
            selectedTestata.righe.push(newRiga);
            
            let newTestata =  oldTestata.map(singleLine => {
                if(singleLine.id == this.props.id){
                    return selectedTestata;
                }
                return singleLine;
            });
            localStorage.setItem(testata_ordini.id, JSON.stringify(newTestata));
            
            let newState = {};
            testata_ordini.righe.map(ordine => {
                newState[ordine.id] = '';
            });
            this.setState(newState);
        }
        
        
        
    }
    
    render(){
        const { classes } = this.props;
        
        let data = localStorage.getItem(testata_ordini.id);
        if(data != null){
            data = JSON.parse(data);
        }else{
            data = [];
        }
        
        let righe = [];
        data.forEach(singleLine => {
            if(singleLine.id == this.props.id){
                righe = singleLine.righe;
            }
        });
                
        return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <RouterLink to="/" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
                    <Link color="inherit" href="/" style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                        <ArrowBackIos style={{ fontSize: "1rem" }} />{/*Testate*/}
                    </Link>
                </RouterLink>
            </Breadcrumbs>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {testata_ordini.testata.map(ordine => {
                    return <TableCell>{ordine.name}</TableCell>
                })}
              </TableRow>
            </TableHead>
            <TableBody>
            {
                data.filter(singleLine => {
                    if(singleLine.id == this.props.id){
                        return true;
                    }
                    return false;
                }).map((singleLine) => {
                    return (<TableRow key={singleLine.id}>
                        {testata_ordini.testata.map(ordine => {
                            if(ordine.type == 'date'){
                                let dateValue = new Date(singleLine.testata[ordine.id]);
                                const options = { dateStyle: 'short' };
                                return (
                                      <TableCell>{dateValue.toLocaleString('it', options)}</TableCell>
                                )
                            }
                            return (
                                  <TableCell>{singleLine.testata[ordine.id]}</TableCell>
                            )})
                        }
                    </TableRow>
                    )
                })
            }
            </TableBody>
          </Table>
          </TableContainer>
          
          <Typography component="h1" variant="h6" color="inherit" noWrap style={{marginTop:"15px"}}>
            
            </Typography>
          <TableContainer component={Paper} style={{height: (window.innerHeight - 340)+"px"}}>
          <Table stickyHeader className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {testata_ordini.righe.map(elRiga => {
                    return <TableCell>{elRiga.name}</TableCell>
                })}
                <TableCell>Azione</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {                
                righe.map((singleLine, index) => {
                    return (<TableRow key={singleLine.id}>
                        {testata_ordini.righe.map(ordine => {
                            if(ordine.type == 'date'){
                                let dateValue = new Date(singleLine.values[ordine.id]);
                                const options = { dateStyle: 'short' };
                                return (
                                      <TableCell>{dateValue.toLocaleString('it', options)}</TableCell>
                                )
                            }
                            return (
                                  <TableCell>{singleLine.values[ordine.id]}</TableCell>
                            )})
                        }
                        <TableCell>
                            <IconButton aria-label="Elimina" onClick={(e) => {e.stopPropagation(); this.deleteRow(singleLine.id)}}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    )
                })
            }
            <TableRow>
                {
                    testata_ordini.righe.map(ordine => {
                        if(ordine.type === 'string'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'number'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="number" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'date'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="date" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'time'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="time" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'code'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} value={this.state[ordine.id]} onChange={(e) => {if(e.target.value.match(ordine.regex)){this.setState({[ordine.id]:e.target.value})}}}/></TableCell>
                            )
                        }
                    })
                }
                        <TableCell>
                            <IconButton aria-label="Salva" onClick={(e) => {e.stopPropagation(); this.saveRiga();}}>
                                <SaveIcon />
                            </IconButton>
                        </TableCell>
            </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/*<Button variant="contained" color="primary" style={{marginTop:"15px"}}>
        Evadi ordine
        </Button>*/}
        </div>
        );
    }
}

export default withRouter(withStyles(styles)(Testata))