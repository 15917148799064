export const testata_ordini = {
        id: 'test_1',
        testata: 
        [
            {
                name:   'Ragione Sociale',
                id:     'rag',
                type:   'string'
            },
            {
                name:   'P.IVA',
                id:     'piva',
                type:   'string'
            },
            {
                name:   'Fatturato',
                id:     'fat',
                type:   'number'
            },
            {
                name:   'Data',
                id:     'dat',
                type:   'date'
            },
            {
                name:   'Ora',
                id:     'tim',
                type:   'time'
            },
            {
                name:   'Codice',
                id:     'cod',
                type:   'code',
                regex:  /^([A-Za-z]{0,3}|[A-Za-z]{3}[0-9]{0,3})$/gi //primi tre caratteri poi fino a tre numeri
            }
        ],
        righe:
        [
            {
                name:   'Codice',
                id:     'cod',
                type:   'code',
                regex:  /^([A-Za-z]{0,3}|[A-Za-z]{3}[0-9]{0,3})$/gi //primi tre caratteri poi fino a tre numeri
            },
            {
                name:   'Nome',
                id:     'name',
                type:   'string'
            },
            {
                name:   'Quantità',
                id:     'qty',
                type:   'number'
            },
            
        ]
    };