import React from 'react';
import {withRouter} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import {testata_ordini} from './configurations/testate';

const styles = theme => ({
  table: {
    minWidth: 650,
  },
});


class Testata extends React.Component {
    
    constructor(props){
        super(props);
        
        let newState = {};
        testata_ordini.testata.map(ordine => {
            newState[ordine.id] = '';
        });
        this.state = newState;
    }
    
    resize = () => this.forceUpdate()

    componentDidMount() {
      window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resize)
    }
    
    /*data = [
        {   
            id: 1,
            testata:
            {
                rag:    'A.C.M.E', 
                piva:   '000000', 
                fat:    1000000, 
                dat:    '2020-01-01', 
                tim:    '10:22', 
                cod:    'ART1'
            }, 
            righe:
            [
                {
                    id:     1,
                    cod:    '123', 
                    name:   'Primo articolo', 
                    qty:    5
                },
                {
                    id:     2,
                    cod:    '124', 
                    name:   'Secondo articolo', 
                    qty:    2
                }
            ],
            evasioni:
            [
                [
                    {
                        riga_id:    2,
                        qty:        1
                    }
                ],
                [
                    {
                        riga_id:    1,
                        qty:        3
                    },
                    {
                        riga_id:    2,
                        qty:        1
                    }
                ]
            ]
        }
    ];*/

    handleClick = (event, id) => {
        const { history } = this.props
        history.push("/righe/"+testata_ordini.id+"/"+id);
    }
    
    deleteRow(id){
        let oldTestata = localStorage.getItem(testata_ordini.id);
        if(oldTestata != null){
            oldTestata = JSON.parse(oldTestata);
            let newTestata = oldTestata.filter(ordine => {
                if(ordine.id === id){
                    return false;
                }else{
                    return true;
                }
            });
            localStorage.setItem(testata_ordini.id, JSON.stringify(newTestata));
            this.forceUpdate();
        }
    }
    
    saveTestata = () => {
        let newOrder = {};
        newOrder.testata = {};
        testata_ordini.testata.map(ordine => {
            newOrder.testata[ordine.id] = this.state[ordine.id];
        });
        let oldTestata = localStorage.getItem(testata_ordini.id);
        let id = 1;
        if(oldTestata != null){
            oldTestata = JSON.parse(oldTestata);
            oldTestata.forEach(row => {
                if(row.id >= id){
                    id = row.id+1;
                }
            });
        }else{
            oldTestata = [];
        }
        
        newOrder.id = id;
        newOrder.righe = [];
        newOrder.evasioni = [];
        oldTestata.push(newOrder);
        localStorage.setItem(testata_ordini.id, JSON.stringify(oldTestata));
        let newState = {};
        testata_ordini.testata.map(ordine => {
            newState[ordine.id] = '';
        });
        this.setState(newState);
    }
    
    render(){
        const { classes } = this.props;
        
        let data = localStorage.getItem(testata_ordini.id);
        if(data != null){
            data = JSON.parse(data);
        }else{
            data = [];
        }
        
        return (
        <TableContainer component={Paper} style={{height: (window.innerHeight - 230)+"px"}}>
          <Table stickyHeader className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {testata_ordini.testata.map(ordine => {
                    return <TableCell>{ordine.name}</TableCell>
                })}
                <TableCell>Azione</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
                data.map((singleLine, index) => {
                    return (<TableRow key={singleLine.id} onClick={event => this.handleClick(event, singleLine.id)}>
                        {testata_ordini.testata.map(ordine => {
                            if(ordine.type == 'date'){
                                let dateValue = new Date(singleLine.testata[ordine.id]);
                                const options = { dateStyle: 'short' };
                                return (
                                      <TableCell>{dateValue.toLocaleString('it', options)}</TableCell>
                                )
                            }
                            return (
                                  <TableCell>{singleLine.testata[ordine.id]}</TableCell>
                            )})
                        }
                        <TableCell>
                            <IconButton aria-label="Elimina" onClick={(e) => {e.stopPropagation(); this.deleteRow(singleLine.id)}}>
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    )
                })
            }
            <TableRow>
                {
                    testata_ordini.testata.map(ordine => {
                        if(ordine.type === 'string'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'number'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="number" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'date'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="date" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'time'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} type="time" value={this.state[ordine.id]} onChange={(e) => {this.setState({[ordine.id]:e.target.value})}}/></TableCell>
                            )
                        }else if(ordine.type === 'code'){
                            return (
                                  <TableCell><TextField id={ordine.id} label={ordine.name} InputLabelProps={{shrink: true }} value={this.state[ordine.id]} onChange={(e) => {if(e.target.value.match(ordine.regex)){this.setState({[ordine.id]:e.target.value})}}}/></TableCell>
                            )
                        }
                    })
                }
                        <TableCell>
                            <IconButton aria-label="Salva" onClick={(e) => {e.stopPropagation(); this.saveTestata();}}>
                                <SaveIcon />
                            </IconButton>
                        </TableCell>
            </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        );
    }
}

export default withRouter(withStyles(styles)(Testata))